@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=DM+Serif+Display&family=Libre+Baskerville:wght@700&family=Taviraj:wght@800&family=Volkhov:wght@700&display=swap');

html {
	margin: 0;
	padding: 0;
}
article {
	font-family: 'Volkhov', serif;
}
.works {
	font-family: 'Volkhov', serif;
}
